<template>
    <modal ref="modalInformacionPago" titulo="Información del pago" tamano="modal-lg" class="modal-informacion-pago">
        <div class="row">
            <div class="col-12 col-md-6 col-lg">
                <!--info-->
                <div class="row mb-2">
                    <div class="col-auto">
                        <p class="f-17 f-500 d-middle">
                            <img v-if="[31,32,33].includes(pago.estado)" src="/img/generales/pago_online.svg" alt="" class="pr-2" />
                            <img v-else src="/img/generales/pago_efectivo.svg" alt="" class="pr-2" />
                            {{ convertMoney(pago.valor) }}
                        </p>
                    </div>
                    <div class="col-auto my-auto">
                        <span v-show="[22,32].includes(pago.estado)" class="bg-verde-fuerte br-4 px-2 py-1 f-14 text-white">Aprobado</span>
                        <span v-show="[21,31].includes(pago.estado)" class="bg-pendiente br-4 px-2 py-1 f-14 text-muted">Pendiente</span>
                        <span v-show="[23,33].includes(pago.estado)" class="bg-rojo-claro br-4 px-2 py-1 f-14 text-white">Rechazado</span>
                        <span v-show="pago.estado == 12" class="bg-azulclaro br-4 px-2 py-1 f-14 text-center">Paz y salvo</span>
                    </div>
                </div>
                <div class="row f-15">
                    <div class="col-12 col-md-6 mb-0 mb-lg-2 my-2">
                        <p class="f-500">Administración </p>
                    </div>
                    <div class="col-12 col-md-6 mb-0 mb-lg-2">
                        <p class="f-100">{{ _.startCase(formatearFecha(pago.fecha_pago_mes,'MMMM YYYY')) }}</p>
                    </div>
                    <div class="col-12 col-md-6 mb-0 mb-lg-2 my-2">
                        <p class="f-500">Fecha y hora de registro </p>
                    </div>
                    <div class="col-12 col-md-6 mb-0 mb-lg-2 my-2">
                        <p class="f-100">{{ formatearFecha(pago.created_at,'DD MMM yyyy h:m a') }}</p>
                    </div>
                    <div class="col-12 col-md-6 mb-0 mb-lg-2 my-2">
                        <p class="f-500">Medio de pago</p>
                    </div>
                    <div class="col-12 col-md-6 mb-0 mb-lg-2 my-2">
                        <p v-if="[31,32,33].includes(pago.estado)">Pago en línea</p>
                        <p v-else-if="pago.estado == 12">Paz y salvo</p>
                        <p v-else>Efectivo</p>
                    </div>
                </div>
                <div v-if="pago.estado != 33 && pago.estado != 32" class="row mt-3">
                    <div class="col-12 f-15">
                        <p v-if="pago.comentario_residente" class="f-500 mb-1">Comentarios del residente</p>
                        <p>{{ pago.comentario_residente }}</p>
                        <p v-if="pago.comentario_admin" class="f-500 mb-1">Comentarios del administrador</p>
                        <p>{{ pago.comentario_admin }}</p>
                    </div>
                </div>
            </div>
            <div v-if="pago.estado != 33 && pago.estado != 32" class="col-12 col-sm-8 col-md-5 col-lg-auto mt-3 mt-md-0">
                <div v-if="pago.adjunto" class="border br-4 card-img border p-3">
                    <img :src="pago.adjunto" alt="" class="w-100 shadow h-100" />
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
export default {
    data(){
        return{
            textarea1:'',
            pago: {
                adjunto: null,
                comentario_admin: null,
                comentario_residente: null,
                created_at: '',
                estado: null,
                fecha_pago_mes: '',
                tipo: null,
                valor: null
            }
        }
    },
    methods:{
        toggle(pago){
            this.pago = pago
            this.$refs.modalInformacionPago.toggle()
        }
    }
}
</script>
<style lang="scss" scoped>
.modal-informacion-pago{
    .estado-aprobado{
        background: #28D07B;
        color: #FFFFFF;
    }
}
</style>